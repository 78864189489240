import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, catchError, of, switchMap, tap, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "../user/user.service";
import CryptoJS from 'crypto-js';
import { AuthUtils } from "./auth.utils";

@Injectable()
export class AuthService {

    private _authenticated: boolean = false;
    private url: string = `${environment.urlWeb}`;
    private secretKey: string = environment.secrectKey;
    private _userTypes: BehaviorSubject<any[] | null> = new BehaviorSubject(
        null
    );
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for access token
     */
    set accessTokenRefresh(token: string) {
        localStorage.setItem('accessTokenRefresh', token);
    }

    get accessTokenRefresh(): string {
        return localStorage.getItem('accessTokenRefresh') ?? '';
    }

    get userRole(): string{
        if(localStorage.getItem('eyed-role')){
            let textoDec = localStorage.getItem('eyed-role');
            let data = CryptoJS.AES.decrypt(textoDec, this.secretKey).toString(CryptoJS.enc.Utf8);
            return JSON.parse(data);
        }

        return '0';
    }

    /**
     * Getter for countries
     */
    get userTypes$(): Observable<any[]> {
        return this._userTypes.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        let params = new HttpParams();
        params = params.set('email', email);
        return this._httpClient.get(`${this.url}authentication/forgotpassword`, {params});
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(data: any): Observable<any> {
        return this._httpClient.post(`${this.url}authentication/resetpassword`, data);
    }

     /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('El usuario ya esta logueado.');
        }

        return this._httpClient.post(`${this.url}authentication/signin`, credentials).pipe(
            switchMap((response: any) => {
                const { accessToken, user, refreshToken } = response;

                // Store the access token in the local storage
                this.accessToken = accessToken;
                this.accessTokenRefresh = refreshToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = user;

                this._userService.suserData = user.role;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Renew token
        // let headers = new HttpHeaders();
        // headers = headers.set('Authorization', 'Bearer ' + this.accessTokenRefresh);
        return this._httpClient.get(`${this.url}authentication/refresh`)
            .pipe(catchError(() =>
                    // Return false
                    of(false)
                    
                ),
                switchMap((response: any) => {
                    const { accesstoken, refreshToken } = response;
                    // Store the access token in the local storage
                    this.accessToken = accesstoken;
                    this.accessTokenRefresh = refreshToken;

                    const { role } = response.user;
                    // Store the user on the user service
                    this._userService.user = response.user;

                    this._userService.suserData = role;
                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Return true
                    return of(true);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('accessTokenRefresh');
        localStorage.removeItem('eyed-role');
        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign out
     */
    signOutCheckOut()
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('accessTokenRefresh');
        localStorage.removeItem('eyed-role');
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user): Observable<any>
    {
        return this._httpClient.post(`${this.url}users/create`, user);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUpTaller(user): Observable<any>
    {
        return this._httpClient.post(`${this.url}users/singup/taller`, user);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    getUserTypes(): Observable<any>{
        return this._httpClient.get<any[]>(`${this.url}authentication/types`).pipe(
            tap((response: any) => {
                this._userTypes.next(response);
            })
        );
    }

    getFieldsByUserTypes(type:any): Observable<any>{
        let params = new HttpParams();
        params = params.set('type', type);
        return this._httpClient.get<any[]>(`${this.url}authentication/fields`, {params});
    }

    existUser(email:any): Observable<any>{
        let params = new HttpParams();
        params = params.set('email', email);
        return this._httpClient.get<any[]>(`${this.url}authentication/existUser`, {params});
    }
}