import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'image-fondo-app',
    templateUrl: 'imagen-fondo.component.html',
    styleUrls: ['./imagen-fondo.component.scss']
})

export class ImagenFondoComponent {

    public url : any; 
    
    constructor(private router: Router) {  
        this.router.events.subscribe((event) => {
              if (event instanceof NavigationEnd) {
                this.url = event.url;
              }
        });
      }
}