import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ElementsComponent } from './elements/elements.component';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { content } from './shared/routes/content-routes';
import { AuthComponent } from './auth/auth.component';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { UserComponent } from './user/user.component';
import { UserGuard } from './core/auth/guards/user.guard';
import { AdminGuard } from './core/auth/guards/admin.guard';
import { SignOutComponent } from './auth/sign-out/sign-out.component';
import { TallerGuard } from './core/auth/guards/taller.guard';
import { TallerComponent } from './taller/taller.component';
import { LandingComponent } from './landing/landing.component';
import { ImagenFondoComponent } from './imagen-fondo/imagen-fondo.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [AdminGuard],
    canActivateChild: [AdminGuard],
    component: ContentLayoutComponent,
    children: content
  },
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: AuthComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: SignOutComponent,
    loadChildren: () => import('./auth/sign-out/sign-out.module').then(m => m.SignOutModule)
  },
  {
    path: 'user',
    canActivate: [UserGuard],
    canActivateChild: [UserGuard],
    component: UserComponent,
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'taller',
    canActivate: [TallerGuard],
    canActivateChild: [TallerGuard],
    component: TallerComponent,
    loadChildren: () => import('./taller/taller.module').then(m => m.TallerModule)
  },
  {
    path: '',
    component: LandingComponent,
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: '',
    component: ImagenFondoComponent,
    loadChildren: () => import('./imagen-fondo/imagen-fondo.module').then(m => m.ImagenFondoModule)
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  // },
  // { 
  //   path: 'elements', 
  //   component: ElementsComponent,
  //   loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: 'login',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
