import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavAdminService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Pulseras', icon: 'aperture', type: 'sub', active: false, children: [
				{ path: '/pulseras/list', title: 'Lista', type: 'link' },
				{ path: '/pulseras/enviadas', title: 'Enviadas', type: 'link' },
				{ path: '/pulseras/produccion', title: 'Produccion', type: 'link' },
				{ path: '/pulseras/solicitudes', title: 'Solicitudes', type: 'link' },
				// { path: '/pulseras/pendientes-pago', title: 'Pendiente pago', type: 'link' },
			]
		},
		{
			title: 'Ordenes', icon: 'package', type: 'sub', active: false, children: [
				{ path: '/ordenes/list', title: 'Lista', type: 'link' },
				{ path: '/ordenes/pendientes-pago', title: 'Pendientes pago', type: 'link' },
				// { path: '/talleres/solicitudes', title: 'Solicitudes', type: 'link' },
			]
		},
		{
			title: 'Talleres', icon: 'briefcase', type: 'sub', active: false, children: [
				{ path: '/talleres/list', title: 'Lista', type: 'link' },
				{ path: '/talleres/solicitudes', title: 'Solicitudes', type: 'link' },
			]
		},
		{
			title: 'Usuarios', icon: 'user', type: 'sub', active: false, children: [
				{ path: '/usuarios/list', title: 'Lista', type: 'link' },
				// { path: '/talleres/solicitudes', title: 'Solicitudes', type: 'link' },
			]
		},
		{
			title: 'Tipos pulsera', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/types/list', title: 'Lista', type: 'link' },
				// { path: '/talleres/solicitudes', title: 'Solicitudes', type: 'link' },
			]
		},
		{
			path: 'parameters' ,title: 'Parámetros', icon: 'grid', type: 'link', active: false, badgeType: 'primary',
		},
		
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
