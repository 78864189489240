<app-header-one [sticky]="true" *ngIf="url != '/pages/comingsoon'"></app-header-one>
<app-breadcrumb [title]="'Taller'" [breadcrumb]="'Dashboard Taller'"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 back-list-nav">
                <div class="account-sidebar" (click)="ToggleDashboard()">
                    <a  class="popup-btn">
                        Mi cuenta
                    </a>
                </div>
                <div class="dashboard-left" [class.open]="openDashboard">
                    <div class="collection-mobile-back" (click)="ToggleDashboard()">
                        <span class="filter-back">
                            <i class="fa fa-angle-left" aria-hidden="true"></i> Volver
                        </span>
                    </div>
                    <div class="block-content dahsboard-routes-table">
                        <ul>
                            <li routerLinkActive="active"><a [routerLink]="['/taller/dashboard']" >Panel taller</a></li>
                            <li routerLinkActive="active"><a [routerLink]="['/taller/observaciones']" >Observaciones</a></li>
                            <li class="last"><a [routerLink]="['/sign-out']">Cerrar sesíon</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</section>
<app-footer-one *ngIf="url != '/pages/comingsoon'"></app-footer-one>
