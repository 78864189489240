import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class UserService{
    private _user: ReplaySubject<any> = new ReplaySubject<any>(1);
    private secretKey: string = environment.secrectKey;

    private url: string = `${environment.urlWeb}`;
    private _userTypes: BehaviorSubject<any[] | null> = new BehaviorSubject(
        null
    );
    private _userManillas: BehaviorSubject<any[] | null> = new BehaviorSubject(
        null
    );
    private _manillaSelected: BehaviorSubject<any[] | null> = new BehaviorSubject(
        null
    );

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: any)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<any>
    {
        return this._user.asObservable();
    }

    set suserData(userData: any) {
        let textoEnc = CryptoJS.AES.encrypt( JSON.stringify(userData), this.secretKey).toString();
        localStorage.setItem('eyed-role', textoEnc);
    }

    get getUserData(): any {
        if(localStorage.getItem('eyed-role')){
            let textoDec = localStorage.getItem('eyed-role');
            let data = CryptoJS.AES.decrypt(textoDec, this.secretKey).toString(CryptoJS.enc.Utf8);
            return data;
        }

        return '0';
    }

    /**
     * Getter for user types
     */
    get userTypes$(): Observable<any[]> {
        return this._userTypes.asObservable();
    }

    /**
     * Getter for user manillas
     */
    get userManillas$(): Observable<any[]> {
        return this._userManillas.asObservable();
    }

    /**
     * Getter for manilla selected
     */
    get manillaSelected$(): Observable<any[]> {
        return this._manillaSelected.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    getUserTypes(): Observable<any>{
        return this._httpClient.get<any[]>(`${this.url}tipos`).pipe(
            tap((response: any) => {
                this._userTypes.next(response);
            })
        );
    }

    getUserManillas(): Observable<any>{
        return this._httpClient.get<any[]>(`${this.url}manillas/MisManillas`).pipe(
            tap((response: any) => {
                this._userManillas.next(response);
            })
        );
    }

    getManillaById(id:any): Observable<any>{
        return this._httpClient.get<any[]>(`${this.url}manillas/findById/${id}`);
    }

    getManillaByIdPago(id:any): Observable<any>{
        return this._httpClient.get<any[]>(`${this.url}manillas/getManillaByIdPago?pagoId=${id}`);
    }

    getUserManillasUpdate(): Observable<any>{
        return this._httpClient.get<any[]>(`${this.url}manillas/MisManillas`);
    }

    updateManillasList(manillas:any): void{
        this._userManillas.next(manillas);
    }

    updateManillaSelected(manilla:any): void{
        this._manillaSelected.next(manilla);
    }

    getFieldsByUserTypes(type:any): Observable<any>{
        let params = new HttpParams();
        params = params.set('type', type);
        return this._httpClient.get<any[]>(`${this.url}authentication/fields`, {params});
    }

    // Manillas
    crearSolicitudManilla(data:any): Observable<any>{
        return this._httpClient.post<any[]>(`${this.url}manillas/solicitar`, data);
    }
    
    crearVariasManilla(data:any): Observable<any>{
        return this._httpClient.post<any[]>(`${this.url}manillas/solicitarVarias`, data);
    }

    updateUserById(data:any, id:any): Observable<any>{
        return this._httpClient.patch<any[]>(`${this.url}users/editarUsuario/${id}`, data);
    }

    updatePasswordById(data:any){
        return this._httpClient.patch<any[]>(`${this.url}users/ChangePassword`, data);
    }

    editarManillaById(id:any, data:any): Observable<any>{
        return this._httpClient.post<any[]>(`${this.url}manillas/update/${id}`, data);
    }

    editarPaymentIntent(id:any, data:any): Observable<any>{
        return this._httpClient.patch<any[]>(`${this.url}pagos/actualizarIntento/${id}`, data);
    }
}