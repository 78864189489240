<!--footer section -->
<footer [class]="class">
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<!-- <h4>Sobre nosotros</h4> -->
					</div>
					<div class="footer-contant">
						<a class="footer-logo" href="https://eyedmx.com/" target="_self">
							<img [src]="themeLogo" alt="logo">
						</a>
						<p>EYEDMX, Ride safe, Ride smart </p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/eyedmx/" target="_blank"><i class="fa fa-facebook f-20" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.tiktok.com/@eyedmx" target="_blank" style="color: black; font-size: 20px;">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
											<path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"/>
										</svg>
									</a>                 
                                </li>
                                <li>
                                    <a href="https://twitter.com/eyed_mx" target="_blank" style="color: black; font-size: 20px;">
										<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
									</a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/eyedmx" target="_blank"><i class="fa fa-instagram f-20" aria-hidden="true"></i></a>
                                </li>
								<i class="bi bi-tiktok"></i>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Sobre nosotros</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a [routerLink]="['/contact']" href="javascript:void(0)">Contáctanos</a></li>
                                <li><a href="https://eyedmx.com/politica-de-privacidad/" target="_self">Políticas de privacidad</a></li>
                                <!-- <li><a href="javascript:void(0)">Términos de servicio</a></li> -->
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>Información EyeDmx</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>Camino real a Cholula 44-05 
									Villa Zavaleta plaza Santa Fe 
									Local 1C
									</li>
						        <li><i class="fa fa-phone"></i>Llamanos: +52 222 - 519 - 4178</li>
						        <li><i class="fa fa-envelope-o"></i>Contáctanos: <a >info@eyedmx.com</a></li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} EyeDmx</p>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->