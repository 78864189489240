<app-header-one [sticky]="true"></app-header-one>
<app-breadcrumb [title]="'Sesíon cerrada'" [breadcrumb]="'Sesíon cerrada'"></app-breadcrumb>
<!-- section start -->
<div class="contenedor-form">
    <div class="formulario-side">
        <h3 style="text-align: center;">Esperamos verte de nuevo</h3>
        <h2>¡Has cerrado sesión!</h2>
    </div>
    <div class="overlay-side">
        <h1>Bienvenido a <img class="img-logo-login" src="../../../assets/images/icon/logo-border.png" width="100px" height="auto" alt=""></h1>
        <!-- Redirect countdown -->
        <ng-container *ngIf="countdown > 0">
            <p>Redireccionando en <strong>{{countdown | i18nPlural: countdownMapping }}</strong></p>
        </ng-container>

        <!-- Redirect message -->
        <ng-container *ngIf="countdown === 0">
            <p>¡Estás siendo redireccionado ahora!</p>
        </ng-container>
        <button class="ghost" [routerLink]="['/login']" id="signUp">Iniciar sesíon</button>
    </div>
</div>
<!-- <section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>¡Has cerrado sesión!</h3>
            </div>
            <div class="col-lg-6 right-login">
                <h3>Iniciar Sesion</h3>
                <div class="theme-card authentication-right">
                    <ng-container *ngIf="countdown > 0">
                        <p>Redireccionando en <strong>{{countdown | i18nPlural: countdownMapping }}</strong></p>
                    </ng-container>
                    <ng-container *ngIf="countdown === 0">
                        <p>¡Estás siendo redireccionado ahora!</p>
                    </ng-container>
                    <button [routerLink]="['/login']" class="btn btn-solid">Iniciar sesíon</button>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--Section ends-->
<app-footer-one></app-footer-one>