import { Component, OnInit, Input, HostListener, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'src/app/core/user/user.service';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit, OnDestroy {
  
  @Input() class: string;
  @Input() themeLogo: string = 'https://eyedmx-bucket.s3.amazonaws.com/resources/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;

  user: any = {};

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  showInfo: boolean = false;

  constructor(
    private _userService: UserService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    // Subscribe to user changes
    let accesToken = localStorage.getItem('accessToken');
    if(accesToken !== '' && accesToken !== null && accesToken !== undefined){
      this.showInfo = false;
    }else{
      this.showInfo = true;
    }
    this._changeDetectorRef.markForCheck();
  }

  /**
     * On destroy
     */
  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

}
