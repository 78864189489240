import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../admin/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'pulseras',
    loadChildren: () => import('../../admin/manillas/manillas.module').then(m => m.ManillasModule),
  },
  {
    path: 'ordenes',
    loadChildren: () => import('../../admin/orders/orders.module').then(m => m.OrdersModule),
  },
  {
    path: 'talleres',
    loadChildren: () => import('../../admin/taller-admin/taller-admin.module').then(m => m.TallerAdminModule),
  },
  {
    path: 'usuarios',
    loadChildren: () => import('../../admin/users-admin/user-admin.module').then(m => m.UserAdminModule),
  },
  {
    path: 'types',
    loadChildren: () => import('../../admin/types-admin/types-admin.module').then(m => m.TypesAdminModule),
  }
  ,
  {
    path: 'parameters',
    loadChildren: () => import('../../admin/parameters/parameters.module').then(m => m.parametersModule),
  }
];