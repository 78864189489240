<div>
    <div class="page-wrapper">
        <app-header-admin (rightSidebarEvent)="rightSidebar($event)"></app-header-admin>
        <div class="page-body-wrapper">
            <div class="page-sidebar" [class.open]="navServices.collapseSidebar">
                <app-sidebar></app-sidebar>
            </div>
            <div class="page-body">
                <app-breadcrumb-admin></app-breadcrumb-admin>
                <main [@animateRoute]="getRouterOutletState(o)">
                    <router-outlet #o="outlet"></router-outlet>
                </main>
                <!-- <router-outlet></router-outlet> -->
            </div>
            <footer class="footer">
                <app-footer-admin></app-footer-admin>
            </footer>
        </div>
    </div>
    <!-- <div class="btn-light custom-theme" [class.rtl]="layoutClass" (click)="clickRtl(layoutType)">{{layoutType}}</div> -->
</div>