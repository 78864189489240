import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, finalize, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: "app-sign-out",
  templateUrl: "./sign-out.component.html",
  styleUrls: ["./sign-out.component.scss"],
})
export class SignOutComponent implements OnInit, OnDestroy {

  countdown: number = 5;
  countdownMapping: any = {
      '=1'   : '# segundo',
      'other': '# segundos'
  };

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._authService.signOut();
      // Redirect after the countdown
      timer(1000, 1000)
      .pipe(
          finalize(() => {
              this._router.navigate(['login']);
          }),
          takeWhile(() => this.countdown > 0),
          takeUntil(this._unsubscribeAll),
          tap(() => this.countdown--)
      )
      .subscribe();
  }

  /**
     * On destroy
     */
  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
  }


}
