<header class="sticky" [ngClass]="class" >
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Bienvenido a EYEDMX</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Llamanos: 123 - 456 - 7890</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-end">
          <ul class="header-dropdown">
            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> Mi cuenta
              <ul class="onhover-show-div">
                <li>
                  <a data-lng="es" [routerLink]="['/user/dashboard']">
                    Perfil
                  </a>
                </li>
                <li>
                  <a data-lng="es" [routerLink]="['/sign-out']">
                    Cerrar sesíon
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <!-- <app-left-menu></app-left-menu> -->
            <div class="brand-logo">
              <a routerLink="/user/dashboard" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
